const process = [
  {
    title: 'Analysis of position and requirement',
    text: 'Which includes understanding the client organization with regard to current business activities, future expansion plans, work culture and extends to mapping competencies for specific position including job title, deliverables, Key accountabilities and other important details which are required to bring success to the position.',
  },
  {
    title: 'Sourcing Strategy',
    text: 'Involves drawing up a list of target companies from which to recruit and utilizing our network, along with our database then we list most potential candidates within target companies. We then contact candidates and share the opportunity with them and draw their interest in the position.',
  },
  {
    title: 'Testing and Evaluation ',
    text: 'of candidates is done to establish a match of candidate profiles with the requirement and work culture of client organization. In addition to personal interviews, we also use psychometric assessment tools.',
  },
  {
    title: 'Reference Checks',
    text: 'of candidate(s) selected for hire is done before the formal offer is made. We can also help to conduct a background check on these candidates, through our trusted partner agencies if required by the client.',
  },
  {
    title: 'Negotiation',
    text: 'is done for the final candidate’s on compensation and other requirements and if required, we assist in finalizing the hiring arrangements.',
  },
  {
    title: 'Post joining follow ups',
    text: 'are done with both the client and candidate and any issue arising is resolved by discussions to ensure the satisfaction of both client and candidate.',
  },
];

const para = {
  alignment: 'text-justify',
  text: `At ATES, we have a systematic approach for selecting candidates. Our
  Recruitment Process begins with analysis of position and requirement and
  goes all the way to post recruitment follow-up with both client and
  candidate. The key principles of our search methodology focus on`,
};

export { process, para };
