<template>
  <div class="pointer-body">
    <section v-for="data in pointers" :key="data.title">
      <h4 class="subheader">{{ data.title }}</h4>
      <p>{{ data.text }}</p>
    </section>
  </div>
</template>

<script>
export default {
  props: ['pointers'],
};
</script>

<style scoped>
p {
  text-align: justify;
  line-height: 2rem;
  font-size: 16px;
  margin-top: 10px;
}
.subheader {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--theme);
  margin-top: 10px;
}

@media (max-width: 600px) {
  p {
    line-height: 1.6rem;
  }
}
</style>
