<template>
  <div id="recruitment-body">
    <section class="page-view">
      <h1 class="header-title">Recruitment Methodology</h1>
      <Article :article="para" />
      <img src="/rec-cropped.png" alt="Recruitment process" class="img-hero" />
      <Pointers :pointers="process" />
    </section>
    <Footer />
  </div>
</template>

<script>
import { process, para } from '@/assets/scripts/recruitment.js';
import Pointers from '@/components/UI/Pointers.vue';
import Article from '@/components/UI/Article.vue';
import Footer from '@/components/UI/Footer.vue';
export default {
  components: { Pointers, Article, Footer },
  data() {
    return {
      process,
      para,
    };
  },
};
</script>

<style scoped>
img {
  max-height: 400px;
}
</style>
